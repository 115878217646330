import React from 'react';
const Shop = React.lazy(() => import('../common/pages/Shop'));
const Orders = React.lazy(() => import('../common/pages/Orders'));
const HomePrestataire = React.lazy(() => import('../common/pages/HomePrestataire'));
const Register = React.lazy(() => import('../common/pages/Auth/Register'));
const Login = React.lazy(() => import('../common/pages/Auth/Login'));
const Home = React.lazy(() => import('../common/pages/Home'));

const routes = [
    {
        path: '/',
        exact: true,
        auth: false,
        verify: false,
        component: Home
    },
    {
        path: '/register',
        exact: true,
        auth: false, 
        verify: false,
        component: Register
    },
    {
        path: '/login',
        exact: true,
        auth: false, 
        verify: false,
        component: Login
    },
    {
        path: '/shop',
        exact: true,
        auth: true, 
        verify: false,
        component: Shop
    },
    { 
        path: '/board',
        exact: true,
        auth: true, 
        verify: false,
        component: HomePrestataire
    },
    { 
        path: '/orders/:id',
        exact: true,
        auth: true, 
        verify: false,
        component: Orders
    }
    
];

export default routes;