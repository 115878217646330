import React from 'react';
// import Login from './common/pages/Login';
// import { Route, BrowserRouter as Router} from 'react-router-dom';
import './styleSheets/App.css';
import Routes from './routes'

function App() {
    return (
        <Routes/>
  );
}

export default App;

