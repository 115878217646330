import * as ActionTypes from '../action-types'

export function authLogin(payload){
    return {
        type: ActionTypes.AUTH_LOGIN,
        payload
    }
}


export function authLogout(){
    return {
        type: ActionTypes.AUTH_LOGOUT
    }
}

export function authCheck(){
    return {
        type:ActionTypes.AUTH_CHECK
    }
}

export function authVerify(payload){
    return {
        type: ActionTypes.AUTH_VERIFY,
        payload
    }
}

export function changeNavigatorTheme(payload){
    return {
        type: ActionTypes.CHANGE_N_T,
        payload
    } 
}

export function auth_t(payload){
    return {
        type: ActionTypes.AUTH_T,
        payload
    } 
}
export function profile(payload){
    return {
        type: ActionTypes.PROFILE_T,
        payload
    }
}
export function classes(payload){
    return {
        type: ActionTypes.CLASSES_T,
        payload
    }
}
export function dataTable(payload){
    return {
        type: ActionTypes.DATA_T,
        payload
    }
}
export function limit(payload){
    return {
        type: ActionTypes.LIMIT_T,
        payload
    }
}
export function pageNo(payload){
    return {
        type: ActionTypes.PAGE_NO_T,
        payload
    }
}
export function ramassage(payload){
    return {
        type: ActionTypes.RAMASS_TRANS,
        payload
    }
}
export function mapsRoutes(payload){
    return {
        type: ActionTypes.MAPSROUTES,
        payload
    }
}
export function addBusColor(payload){
    return {
        type: ActionTypes.ADDbUSCOLOR,
        payload
    }
}
export function addMapPosition(payload){
    return {
        type: ActionTypes.ADDMAPPOSITION,
        payload
    }
}
export function shop_action(payload){
    return {
        type: ActionTypes.SHOPACTION,
        payload
    } 
}