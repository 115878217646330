import * as ActionTypes from '../action-types'
import Http from '../../Http'

const initialState = {
    isAuthenticated : false,
    isVerify: true,
    ln: "",
    role: "",
    email: "",
    _ipvkd: "",
    ipvkds: "",
    __dt : "",
    shid: "",
    shrid:"",
    sh_n: ""
};

const Auth = (state= initialState,{type,payload = null}) => {
    switch(type){
        case ActionTypes.AUTH_LOGIN:
            return authLogin(state,payload);
        case ActionTypes.AUTH_CHECK:
            return checkAuth(state);
        case ActionTypes.AUTH_LOGOUT:
            return logout(state);
        case ActionTypes.AUTH_VERIFY:
            return authVerify(state);
        case ActionTypes.PROFILE_T:
            return profile(state,payload);
        case ActionTypes.SHOPACTION:
            return shopAction(state,payload);
        case "CHANGE_N_T":
            return changeFunction(state,payload);
        default:
            return state;
    }
};

const shopAction = (state,payload) => {
    state = Object.assign({}, state, payload);
    return state;
};

const authLogin = (state,payload) => {
    const jwtToken = payload.access_token;
    // const ia_t = JSON.stringify({isAuthenticated: true, isVerify: true});
    localStorage.setItem('jwt_token_pres',jwtToken);
    localStorage.setItem('isAuthenticated',true);
    localStorage.setItem('isVerify',true);
    Http.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
    state = Object.assign({}, state, {
        isAuthenticated: true,
        isVerify: true,
    });
    return state;

};

const checkAuth = (state) =>{
    state =Object.assign({},state,{
        isAuthenticated : !!localStorage.getItem('jwt_token_pres'),
    });
    if(state.isAuthenticated){
        Http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt_token_pres')}`;
    }
    return state;
};

const logout = (state) => {
    localStorage.removeItem('jwt_token_pres');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isVerify');
    state = Object.assign({},state,{
        isAuthenticated: false,
    });
    return state;
};

const authVerify = (state,payload) => {
    state = Object.assign({}, state, {
        isVerify: true,
    });
    return state;
};
const profile = (state,payload) => {
    state = Object.assign({}, state, payload);
    return state;

};
const changeFunction = (state,payload) => {
    state = Object.assign({}, state, {__dt: payload});
    return state;
};

export default Auth;
