import axios from 'axios'
import store from './store'
import * as actions from './store/actions'

axios.defaults.baseURL = 'https://api.wemaza.com/v1'; // Production
// axios.defaults.baseURL = 'http://localhost:8080/v1'; // Development

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


if (localStorage.getItem('jwt_token_pres')) {
	axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt_token_pres')}`;
} else {
	localStorage.removeItem('isVerify');
	localStorage.removeItem('jwt_token_presta');
	localStorage.removeItem('jwt_token');
}

axios.interceptors.response.use(
    response => response,
    (error) => {
        if(error.response.status === 401 || error.response.status === 403 ) {
            store.dispatch(actions.authLogout())
            window.location.href = `/login`;
        }
        return Promise.reject(error);
    }
);
export default axios;
