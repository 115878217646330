import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactPlaceholder_T from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import Stats from './Stats';

class ReactPlaceholder extends Component {
	constructor(props) {
		super(props);
	}
	render(){
        	const { t } = this.props;
        	return(
                	<>
                     <ReactPlaceholder_T customPlaceholder={<Stats />} ready={this.props.infosSchool} >
                        <div className="row" style={{textAlign: 'inherit'}}>
                            <div className="col-3 col-md-3 col-lg-4 col-xl-3">
                            
                            </div>
                        </div>
                      </ReactPlaceholder_T>     
                  </>
        	);
        }
}
export default withTranslation()(ReactPlaceholder);