import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Stats extends Component {
	constructor(props) {
		super();
	}
	render(){
        	const { t } = this.props;
        	return(
                	<div className="container">
                        <div className="row">
                            <div className="col-4 stat_round"></div>
                            <div className="col">
                                <div className="stat_lign1"></div>
                                <div className="stat_lign2"></div>
                            </div>
                        </div>
                  </div>
        	);
        }
}
export default withTranslation()(Stats);