import React from 'react'
import {Route} from 'react-router'



const PublicRoute = ({component, path, ...rest}) => (
    <Route path={path} component={component}/>
);


export default PublicRoute;